
import { Component, Vue } from 'vue-property-decorator'
import { namespace } from 'vuex-class'
import AdminNav from '@/components/AdminNav.vue'

const Auth = namespace('Auth')

@Component({
  components: { AdminNav }
})
export default class AdminBoard extends Vue {
  @Auth.State('user')
  private currentUser!: any;

  mounted (): void {
    if (!this.canShowAdminBoard()) {
      this.$router.push('/home')
    }
  }

  private canShowAdminBoard (): boolean {
    if (this.currentUser && this.currentUser.roles) {
      return this.currentUser.roles.includes('Admin')
    }
    return false
  }
}
