
import { Component, Vue } from 'vue-property-decorator'

@Component
export default class AdminNav extends Vue {
  private navLinks = [{
    path: '/admin/users',
    label: 'Kasutajad'
  }, {
    path: '/admin/toys',
    label: 'Mänguasjad'
  }, {
    path: '/admin/swaps',
    label: 'Vahetused'
  }, {
    path: '/admin/parameters',
    label: 'Otsinguparameetrid'
  }, {
    path: '/admin/settings',
    label: 'Portaali seaded'
  }, {
    path: '/admin/notifications',
    label: 'Teavitused'
  }];
}

