

import { Component, Prop, Vue } from 'vue-property-decorator'

@Component
export default class AdminTablePagination extends Vue {
  @Prop() perPage: number;
  @Prop() totalItems: number;
  @Prop() currentPage: number;

  private getTextForCount (): string {
    return 'Kuva korraga ' + this.perPage
  }

  private changeItemsPerPage (itemsCount: number | undefined) {
    this.$emit('updateItemsPerPage', itemsCount)
  }

  private updateCurrentPage (page: number) {
    this.$emit('updateCurrentPage', page)
  }
}
