import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import VueCookies from 'vue-cookies'
import VeeValidate from 'vee-validate'
import { library } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'
import '@/assets/styles/variables.scss'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import VueMeta from 'vue-meta'
import './class-component-hooks'

import {
  faHome,
  faUser,
  faUserPlus,
  faSignInAlt,
  faSignOutAlt
} from '@fortawesome/free-solid-svg-icons'

library.add(faHome, faUser, faUserPlus, faSignInAlt, faSignOutAlt)

Vue.config.productionTip = false

Vue.use(VeeValidate, {
  fieldsBagName: 'veeFields',
  locale: 'et',
  dictionary: {
    et: {
      attributes: {
        email: 'e-mail',
        password: 'salasõna',
        passwordConfirmation: 'salasõna kinnitus',
        firstName: 'eesnimi',
        lastName: 'perekonnanimi',
        name: 'nimi',
        description: 'kirjeldus',
        price: 'punktid',
        location: 'asukoht',
        ageGroupIds: 'vanusegrupid',
        categoryIds: 'kategooriad',
        conditionId: 'seisukord',
        terms: 'privaatsuspoliitika ja kasutajatingimused',
        referralCode: 'Jagamiskood'
      }
    }
  }
})

Vue.use(BootstrapVue)
Vue.use(IconsPlugin)
Vue.use(VueCookies, { expires: '7d' })

Vue.component('font-awesome-icon', FontAwesomeIcon)

Vue.use(VueMeta, {
  keyName: 'metaInfo',
  attribute: 'data-vue-meta',
  ssrAttribute: 'data-vue-meta-server-rendered',
  tagIDKeyName: 'vmid',
  refreshOnceOnNavigation: true
})

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
