import Vue from 'vue'
import VueRouter, { RouteConfig } from 'vue-router'
import Home from '@/components/pages/homePage/Home.vue'
import Login from '@/components/pages/Login.vue'
import Register from '@/components/pages/Register.vue'
import EmailVerification from '@/components/pages/EmailVerification.vue'
import Profile from '@/components/pages/Profile.vue'
import AdminBoard from './components/pages/adminPages/AdminBoard.vue'
import UserManager from './components/pages/adminPages/UserManager.vue'
import UserBoard from './components/pages/userPages/UserBoard.vue'
import ToyForm from './components/pages/userPages/ToyForm.vue'
import ToyManager from '@/components/pages/adminPages/ToyManager.vue'
import SwapManager from '@/components/pages/adminPages/SwapManager.vue'
import ParameterManager from '@/components/pages/adminPages/ParameterManager.vue'
import PublicProfile from '@/components/pages/PublicProfile.vue'
import Toy from '@/components/pages/Toy.vue'
import ShoppingCart from '@/components/pages/ShoppingCart.vue'
import Orders from '@/components/pages/orderPage/Orders.vue'
import ChatPage from '@/components/pages/ChatPage.vue'
import Instructions from '@/components/pages/Instructions.vue'
import PasswordReset from '@/components/pages/PasswordReset.vue'
import Tips from '@/components/pages/Tips.vue'
import PortalSettingsManager from '@/components/pages/adminPages/PortalSettingsManager.vue'
import Order from '@/components/pages/orderPage/Order.vue'
import NotificationManager from '@/components/pages/adminPages/NotificationManager.vue'
import Legal from '@/components/pages/Legal.vue'

Vue.use(VueRouter)

const routes: Array<RouteConfig> = [
  {
    path: '/',
    name: 'Igale lelule uus elu!',
    component: Home
  },
  {
    path: '/home',
    name: 'Kodu',
    component: Home
  },
  {
    path: '/login',
    name: 'Logi sisse',
    component: Login
  },
  {
    path: '/register',
    name: 'Registreeru',
    component: Register
  },
  {
    path: '/hello',
    name: 'Juhend',
    component: Instructions
  },
  {
    path: '/tips',
    name: 'Nipid',
    component: Tips
  },
  {
    path: '/legal',
    name: 'Legal',
    component: Legal
  },
  {
    path: '/legal/:doc',
    name: 'Privaatsuspoliitika & kasutajatingimused',
    component: Legal,
    props: true
  },
  {
    path: '/register/verification',
    name: 'Emaili verifitseerimine',
    component: EmailVerification
  },
  {
    path: '/register/reset',
    name: 'Salasõna taastamine',
    component: PasswordReset
  },
  {
    path: '/profile',
    name: 'Profiili muutmine',
    component: Profile,
    beforeEnter: (to, from, next) => {
      if (needToLogin(true)) {
        next('/login?redirect=' + encodeURIComponent(to.path))
      } else {
        next()
      }
    }
  },
  {
    path: '/profile/:id',
    name: 'Profiil',
    component: PublicProfile,
    props: true, // passes :id to component as prop
    beforeEnter: (to, from, next) => {
      if (needToLogin(true)) {
        next('/login?redirect=' + encodeURIComponent(to.path))
      } else {
        next()
      }
    }
  },
  {
    path: '/admin',
    name: 'Admin',
    component: AdminBoard,
    beforeEnter: (to, from, next) => {
      if (needToLogin(true)) {
        next('/login?redirect=' + encodeURIComponent(to.path))
      } else {
        next()
      }
    },
    children: [
      {
        path: 'users',
        name: 'Admin | Kasutajad',
        component: UserManager
      }, {
        path: 'toys',
        name: 'Admin | Lelud',
        component: ToyManager
      }, {
        path: 'swaps',
        name: 'Admin | Vahetused',
        component: SwapManager
      }, {
        path: 'parameters',
        name: 'Admin | Otsinguparameetrid',
        component: ParameterManager
      }, {
        path: 'settings',
        name: 'Admin | Portaali seaded',
        component: PortalSettingsManager
      }, {
        path: 'notifications',
        name: 'Admin | Teavitused',
        component: NotificationManager
      }
    ]
  },
  {
    path: '/user',
    name: 'Kasutaja',
    component: UserBoard,
    beforeEnter: (to, from, next) => {
      if (needToLogin(true)) {
        next('/login?redirect=' + encodeURIComponent(to.path))
      } else {
        next()
      }
    },
    children: [{
      path: 'toy/new',
      name: 'Lisa uus lelu',
      component: ToyForm
    }, {
      path: 'toy/:id',
      name: 'Muuda lelu',
      component: ToyForm,
      props: true // passes :id to component as prop
    }]
  },
  {
    path: '/toy/:id',
    name: 'lelu',
    component: Toy,
    props: true // passes :id to component as prop
  },
  {
    path: '/cart',
    name: 'Vahetuskorv',
    component: ShoppingCart,
    beforeEnter: (to, from, next) => {
      if (needToLogin(true)) {
        next('/login?redirect=' + encodeURIComponent(to.path))
      } else {
        next()
      }
    }
  },
  {
    path: '/orders',
    name: 'Tellimused',
    component: Orders,
    beforeEnter: (to, from, next) => {
      if (needToLogin(true)) {
        next('/login?redirect=' + encodeURIComponent(to.path))
      } else {
        next()
      }
    }
  },
  {
    path: '/order/:id',
    name: 'Tellimus',
    component: Order,
    props: true, // passes :id to component as prop
    beforeEnter: (to, from, next) => {
      if (needToLogin(true)) {
        next('/login?redirect=' + encodeURIComponent(to.path))
      } else {
        next()
      }
    }
  },
  {
    path: '/chat',
    name: 'Vestlused',
    component: ChatPage,
    props: true, // passes :id to component as prop
    beforeEnter: (to, from, next) => {
      if (needToLogin(true)) {
        next('/login?redirect=' + encodeURIComponent(to.path))
      } else {
        next()
      }
    }
  },
  {
    path: '/chat/:id',
    name: 'Vestlus',
    component: ChatPage,
    props: true, // passes :id to component as prop
    beforeEnter: (to, from, next) => {
      if (needToLogin(true)) {
        next('/login?redirect=' + encodeURIComponent(to.path))
      } else {
        next()
      }
    }
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior (to, from, savedPosition) {
    if (!['/login', '/register', '/home', '/'].includes(to.path) && !to.path.startsWith('/profile/')) {
      return { x: 0, y: 0 }
    }
  }
})

function needToLogin (authRequired: boolean): boolean {
  const loggedIn = localStorage.getItem('user')
  return authRequired && !loggedIn
}

router.beforeEach((to, from, next) => {
  document.title = 'Leluelu | ' + to.name
  next()
})

export default router
