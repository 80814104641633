

import { Component, Vue } from 'vue-property-decorator'
import { Notification, UserIdEmailMap } from '@/models/notification'
import AdminService from '@/services/AdminService'
import DateUtilities from '@/utilities/DateUtilities'
import AdminTablePagination from '@/components/pages/adminPages/AdminTablePagination.vue'

@Component({
  components: { AdminTablePagination }
})
export default class NotificationManager extends Vue {
  private notification: Notification = {
    toUserIds: [],
    content: null,
    status: 'new'
  }

  private searchForm = { name: '' }
  private searchResult = [];
  private loaded = false;

  private isAllSelected = false
  private allUsers: UserIdEmailMap[] = []
  private sendDisabled = true
  private showOverlay = false
  private notificationsLog: [] = []
  private currentPage = 1;
  private perPage = 10;
  private itemFields = [
    {
      key: 'id',
      label: 'ID',
      sortable: true
    },
    {
      key: 'content',
      label: 'teade',
      sortable: true
    },
    {
      key: 'status',
      label: 'staatus',
      sortable: true,
      thClass: 'd-none d-sm-table-cell',
      tdClass: 'd-none d-sm-table-cell'
    },
    {
      key: 'toUserId',
      label: 'kasutaja'
    },
    {
      key: 'createdBy',
      label: 'saatja',
      sortable: true,
      thClass: 'd-none d-sm-table-cell',
      tdClass: 'd-none d-sm-table-cell'
    },
    {
      key: 'createdAt',
      label: 'saadetud',
      sortable: true
    }
  ];

  mounted (): void {
    this.loadUserIdEmailMap()
    this.loadAllNotifications()
    if (this.$route.query.userId) {
      this.notification.toUserIds.push(Number(this.$route.query.userId))
    }
  }

  /* eslint-disable dot-notation */
  handleSearch (): void {
    this.searchResult = this.notificationsLog.filter(item => {
      const searchName = this.searchForm.name.toLowerCase()

      const toUserName: string = item['toUserName']
      const userName = toUserName == null ? '' : toUserName.toLowerCase()

      return userName.includes(searchName)
    })
    this.currentPage = 1
  }
  /* eslint-enable dot-notation */

  resetSearch (): void {
    this.searchForm = { name: '' }
  }

  private loadUserIdEmailMap (): void {
    AdminService.fetchAllUsers().then((response) => {
      response.data.forEach(user => {
        this.allUsers.push({
          id: user.id,
          email: user.email,
          name: user.firstName + ' ' + user.lastName
        })
      })
    })
  }

  private loadEmailById (id: number): string {
    return this.allUsers.find(x => x.id === id).email
  }

  private removeUser (id: number): void {
    this.notification.toUserIds = this.notification.toUserIds.filter(x => x !== id)
    this.touched()
  }

  private selectAllUsers () {
    if (this.isAllSelected) {
      this.allUsers.forEach(user => {
        if (!this.notification.toUserIds.includes(user.id)) {
          this.notification.toUserIds.push(user.id)
        }
      })
    } else {
      this.notification.toUserIds = []
    }
  }

  private addUser (val) {
    if (!this.notification.toUserIds.includes(val)) {
      this.notification.toUserIds.push(val)
    }
    this.touched()
  }

  private loadAllNotifications (): void {
    AdminService.fetchAllNotifications().then((response) => {
      this.notificationsLog = response.data
      this.searchResult = response.data
    }).then(() => { this.loaded = true })
  }

  private sendNotification () {
    this.sendDisabled = true
    this.showOverlay = true
    this.$validator.validateAll().then((isValid) => {
      if (isValid) {
        AdminService.sendNotification(this.notification).then(() => {
          window.location.reload()
        })
      }
    })
  }

  private getFormattedDate (timestamp) {
    return DateUtilities.getDateStringFromString(timestamp)
  }

  private touched () {
    this.$validator.validateAll().then((isValid) => {
      if (isValid && this.notification.toUserIds.length > 0) {
        this.sendDisabled = false
      } else {
        this.sendDisabled = true
      }
    })
  }

  private updateItemsPerPage (itemsCount: number | undefined) {
    this.perPage = itemsCount || this.searchResult.length
    this.currentPage = 1
  }

  private updateCurrentPage (currentPage: number) {
    this.currentPage = currentPage
  }
}

