
import { Component, Vue } from 'vue-property-decorator'
import SwapOrderService from '@/services/OrderService'
import DateUtilities from '@/utilities/DateUtilities'
import { namespace } from 'vuex-class'
import { SWAP_STATUS_NAMES, TOY_STATUS_NAMES } from '@/models/id-maps'
import Icon from '@/components/Icon.vue'
import AdminTablePagination from '@/components/pages/adminPages/AdminTablePagination.vue'

const AppInfo = namespace('AppInfo')

@Component({
  components: { AdminTablePagination, Icon }
})
export default class SwapManager extends Vue {
  @AppInfo.Getter
  private allSwapStatuses: [];

  private sortBy = 'updatedAt';
  private sortDesc = true;
  private loaded = false;

  private allItems = [];
  private currentPage = 1;
  private perPage = 10;
  private itemFields = [
    {
      key: 'id',
      label: 'ID',
      sortable: true,
      thClass: 'd-none d-sm-table-cell',
      tdClass: 'd-none d-sm-table-cell'
    },
    {
      key: 'toApplicationUser',
      label: 'soovija'
    },
    {
      key: 'fromApplicationUser',
      label: 'pakkuja'
    },
    {
      key: 'total',
      label: 'punktid',
      sortable: true,
      thClass: 'd-none d-sm-table-cell',
      tdClass: 'd-none d-sm-table-cell'
    },
    {
      key: 'swapStatusId',
      label: 'staatus',
      sortable: true
    },
    {
      key: 'createdAt',
      label: 'vahetus loodud',
      sortable: true,
      thClass: 'd-none d-sm-table-cell',
      tdClass: 'd-none d-sm-table-cell'
    },
    {
      key: 'updatedAt',
      label: 'viimati uuendatud',
      sortable: true,
      thClass: 'd-none d-sm-table-cell',
      tdClass: 'd-none d-sm-table-cell'
    },
    {
      key: 'options',
      label: ''
    }
  ];

  mounted (): void {
    this.fetchAllSwapOrders()
  }

  private fetchAllSwapOrders (): void {
    SwapOrderService.getAllSwapOrders().then(
      (response) => {
        this.allItems = response.data
      }).then(() => { this.loaded = true })
  }

  private getFormattedDate (timestamp) {
    return DateUtilities.getDateTimeStringFromString(timestamp)
  }

  private getSwapStatusName (id) {
    return SWAP_STATUS_NAMES[id]
  }

  private getToyStatusName (id) {
    return TOY_STATUS_NAMES[id]
  }

  private isPendingState (id) {
    return [1, 4].includes(id)
  }

  private updateItemsPerPage (itemsCount: number | undefined) {
    this.perPage = itemsCount || this.allItems.length
    this.currentPage = 1
  }

  private updateCurrentPage (currentPage: number) {
    this.currentPage = currentPage
  }
}

