import { render, staticRenderFns } from "./CategorySection.vue?vue&type=template&id=dc930dea&scoped=true"
import script from "./CategorySection.vue?vue&type=script&lang=ts"
export * from "./CategorySection.vue?vue&type=script&lang=ts"
import style0 from "./CategorySection.vue?vue&type=style&index=0&id=dc930dea&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "dc930dea",
  null
  
)

export default component.exports