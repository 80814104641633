
import { Component, Vue } from 'vue-property-decorator'
import { Toy } from '@/models/toy'
import DateUtilities from '@/utilities/DateUtilities'
import BasicService from '@/services/BasicService'
import AdminService from '@/services/AdminService'
import AdminTablePagination from '@/components/pages/adminPages/AdminTablePagination.vue'
import { namespace } from 'vuex-class'

const AppInfo = namespace('AppInfo')

@Component({
  components: { AdminTablePagination }
})
export default class ToyManager extends Vue {
  @AppInfo.Getter private allToyStatuses: [];
  @AppInfo.Getter private allConditions: [];
  @AppInfo.Getter private allAgeGroups: [];
  @AppInfo.Getter private allCategories: [];
  @AppInfo.Getter private allLocations: [];

  private toys: Toy[] = [];
  private searchResult :Toy[] = [];
  private searchForm = { ownerName: '', location: null, status: null, category: null, ageGroup: null, condition: null }
  private usersLoaded = false;
  private toysLoaded = false;

  private toyStatuses = [
    { text: 'active', value: 1 },
    { text: 'inactive', value: 2 }
  ];

  private allUsers = [];

  private sortBy = 'updatedAt'
  private sortDesc = true

  private currentPage = 1;
  private perPage = 10;

  private itemFields = [
    {
      key: 'id',
      label: 'ID',
      sortable: true,
      thClass: 'd-none d-sm-table-cell',
      tdClass: 'd-none d-sm-table-cell'
    },
    {
      key: 'name',
      label: 'nimetus'
    },
    {
      key: 'applicationUser',
      label: 'kasutaja',
      sortable: true,
      thClass: 'd-none d-sm-table-cell',
      tdClass: 'd-none d-sm-table-cell'
    },
    {
      key: 'price',
      label: 'punktid',
      sortable: true
    },
    {
      key: 'location',
      label: 'asukoht',
      sortable: true,
      thClass: 'd-none d-sm-table-cell',
      tdClass: 'd-none d-sm-table-cell'
    },
    {
      key: 'condition.name',
      label: 'seisukord',
      sortable: true,
      thClass: 'd-none d-sm-table-cell',
      tdClass: 'd-none d-sm-table-cell'
    },
    {
      key: 'createdAt',
      label: 'lisatud',
      sortable: true,
      thClass: 'd-none d-sm-table-cell',
      tdClass: 'd-none d-sm-table-cell'
    },
    {
      key: 'updatedAt',
      label: 'viimati muudetud',
      sortable: true,
      thClass: 'd-none d-sm-table-cell',
      tdClass: 'd-none d-sm-table-cell'
    },
    {
      key: 'toyStatus.name',
      label: 'staatus',
      sortable: true
    },
    {
      key: 'options',
      label: ''
    }
  ];

  private detailFields = [
    {
      key: 'id',
      label: 'ID'
    },
    {
      key: 'name',
      label: 'nimetus'
    },
    {
      key: 'applicationUser',
      label: 'kasutaja'
    },
    {
      key: 'price',
      label: 'punktid'
    },
    {
      key: 'location',
      label: 'asukoht'
    },
    {
      key: 'condition.name',
      label: 'seisukord'
    },
    {
      key: 'createdAt',
      label: 'lisatud'
    },
    {
      key: 'updatedAt',
      label: 'viimati muudetud'
    },
    {
      key: 'toyStatus.name',
      label: 'staatus'
    },
    {
      key: 'toyInAgeGroups',
      label: 'vanusegrupid'
    },
    {
      key: 'toyInCategories',
      label: 'kategooriad'
    },
    {
      key: 'condition',
      label: 'seisukord'
    },
    {
      key: 'description',
      label: 'kirjeldus'
    }
  ];

  mounted (): void {
    this.fetchAllUsers()
    this.fetchAllToys()
  }

  handleSearch (): void {
    this.searchResult = this.toys.filter(item => {
      const searchByName = this.searchForm.ownerName.toLowerCase()
      const firstName = item.applicationUser.firstName == null ? '' : item.applicationUser.firstName.toLowerCase()
      const lastName = item.applicationUser.lastName == null ? '' : item.applicationUser.lastName.toLowerCase()
      const fullName = firstName + ' ' + lastName

      return fullName.includes(searchByName) &&
        (this.searchForm.location === null || item.location === this.searchForm.location) &&
        (this.searchForm.status === null || item.toyStatusId === this.searchForm.status) &&
        (this.searchForm.category === null || item.toyInCategories.filter(cat => cat.categoryId === this.searchForm.category).length > 0) &&
        (this.searchForm.ageGroup === null || item.toyInAgeGroups.filter(ag => ag.ageGroupId === this.searchForm.ageGroup).length > 0) &&
        (this.searchForm.condition === null || item.conditionId === this.searchForm.condition)
    })
    this.currentPage = 1
  }

  resetSearch (): void {
    this.searchForm = { ownerName: '', location: null, status: null, category: null, ageGroup: null, condition: null }
  }

  private fetchAllToys () {
    BasicService.getAllToys().then(
      (response) => {
        this.toys = response.data
        this.searchResult = response.data
      }).then(() => { this.toysLoaded = true })
  }

  private fetchAllUsers (): void {
    AdminService.fetchAllUsers().then(
      (response) => {
        response.data.forEach(row => {
          this.allUsers.push({
            text: row.firstName + ' ' + row.lastName,
            value: row.id
          })
        })
      }).then(() => { this.usersLoaded = true })
  }

  private updateToy (toy) {
    AdminService.updateToyAdmin(toy).then(() => {
      window.location.reload()
    })
  }

  private getFormattedDate (timestamp) {
    return DateUtilities.getDateTimeStringFromString(timestamp)
  }

  private selectDisabled (toy): boolean {
    return [3, 4].includes(toy.toyStatusId)
  }

  private getDetailFields () {
    return this.detailFields
  }

  private updateItemsPerPage (itemsCount: number | undefined) {
    this.perPage = itemsCount || this.searchResult.length
    this.currentPage = 1
  }

  private updateCurrentPage (currentPage: number) {
    this.currentPage = currentPage
  }
}

